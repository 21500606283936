import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/react"
import { style, color, width, mq } from "../utils/styles"

import { color as csxColor } from "csx"

import LogoIcon from "../img/common/logo.svg"

export const headerHeight = `64px`

const Logo: React.FC = () => {
  return (
    <Link
      to="/"
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 1;
        margin-right: 16px;
        ${mq.sp(css`
          margin-left: ${width.spMargin};
        `)}
      `}
    >
      <LogoIcon />
    </Link>
  )
}

const Contact: React.FC = () => {
  return (
    <a
      href="https://yokowake.booking.chillnn.com/"
      target="_brank"
      rel="noopenner"
      css={css`
        display: flex;
        font-size: min(4vw, 1.5rem);
        flex: 0 0 87px;
        color: ${color.white};
        line-height: 1.4;
        text-decoration: none;
        height: ${headerHeight};
        justify-content: center;
        align-items: center;
        padding: 12px;
        background: ${color.main};
        border-left: solid 3px ${color.yellow};
        border-bottom: solid 3px ${color.yellow};
      `}
    >
      ご予約・<br />
      空室検索
    </a>
  )
}

const Header: React.FC = () => {
  return (
    <header
      css={css`
        position: fixed;
        inset: 0 0 auto;
        z-index: 10000;
        background: ${csxColor(color.white).fade(0.6).toString()};
      `}
    >
      <div
        css={css`
          ${style.contentWide}
          display: flex;
          justify-content: space-between;
          ${mq.sp(css`
            width: 100%;
          `)}
        `}
      >
        <Logo />
        <Contact />
      </div>
    </header>
  )
}

export default Header
