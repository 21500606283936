import {FC} from "react"
import Helmet from "react-helmet"
import {color} from "../utils/styles"

type SEOProps = {
  title?: string
  description?: string
}

const SEO:FC<SEOProps> = ({title, description}) => {
  const defaultTitle = `ゲストハウス横分けハンサム＜東京都八丈島の格安ゲストハウス＞`
  const titleSuffix = `横分けハンサム`
  const siteTitle = title ? title + ` | ` + titleSuffix : defaultTitle

  const defaultDescription = `全室個室＆ダブルベッドで広々快適な客室に、Wi-fi完備、食事付きでビジネス利用も大歓迎！ダイビングや釣り、トレッキングなどの旅行にもぜひご利用ください。八丈島空港や底土港からのアクセスも良好です。`
  const siteDescription = description ? description : defaultDescription

  const siteURL = process.env.GATSBY_SITE_URL || "https://yokowake8jo.com"

  return (
    <Helmet>
      <html lang="ja" />
      <title>{siteTitle}</title>
      <meta name="description" content={siteDescription} />

      <link rel="icon" href="/favicon.svg" />
      <link rel="icon alternate" type="image/png" href="/icon-192x192.png" />
      <link rel="apple-touch-icon" type="image/png" href="/apple-touch-icon-180x180.png" />

      <meta name="theme-color" content={color.main} />
      <meta property="og:locale" content="ja_JP" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={siteTitle} />
      <meta property="og:description" content={siteDescription} />
      <meta name="og:image" content={`${siteURL}/og_image.png`} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:description" content={siteDescription} />
      <meta name="twitter:title" content={siteTitle} />
    </Helmet>
  )
}

export default SEO
