import React from "react"
import { Global, css } from "@emotion/react"
import "ress"
import "@fontsource/hind/300.css"

import { fontFamily } from "../utils/styles"
import Header, { headerHeight } from "../components/header"
import Footer from "../components/footer"

const Layout: React.FC = ({ children }) => {
  const fontSize = 14
  const lineHeight = 1.7

  return (
    <>
      <Global
        styles={css`
          html {
            font-size: 62.5%;
          }
          body {
            font-family: ${fontFamily.main};
            font-size: ${fontSize}px;
            font-size: ${fontSize / 10}em;
            line-height: ${lineHeight};
            letter-spacing: normal;
          }
          svg,
          img {
            max-width: 100%;
            vertical-align: bottom;
          }
          ul {
            list-style: none;
          }
        `}
      />
      <Header />
      <div
        css={css`
          margin-top: ${headerHeight};
        `}
      >
        {children}
      </div>
      <Footer />
    </>
  )
}

export default Layout
