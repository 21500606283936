import { css, SerializedStyles, keyframes } from "@emotion/react"

export const breakPoint = 1020
export const mq: {
  pc: (props: SerializedStyles) => SerializedStyles
  sp: (props: SerializedStyles) => SerializedStyles
} = {
  pc: props => {
    return css`
      @media (min-width: ${breakPoint}px) {
        ${props}
      }
    `
  },
  sp: props => {
    return css`
      @media (max-width: ${breakPoint - 1}px) {
        ${props}
      }
    `
  },
}

export const hover = (props: SerializedStyles) => {
  return css`
    @media (hover: hover) {
      ${props}
    }
  `
}

const blue = `#0e2a77`
const blueL = `#8694BA`
const yellow = `#f1d901`
const white = `#fff`
const gray = `#E8E8E8`
const main = blue
export const color = {
  main,
  blue,
  blueL,
  yellow,
  white,
  gray,
}

const baseFontFamily =
  '"Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif'
export const fontFamily = {
  main: baseFontFamily,
  decorateSans: '"Hind", ' + baseFontFamily,
}

export const font = {
  h1: css`
    font-weight: bold;
    font-size: 4.0rem;
    line-height: 1.7;
  `,
  h2: css`
    font-weight: normal;
    font-size: 2.2rem;
    line-height: 1.7;
  `,
  h3: css`
    font-weight: normal;
    font-size: 1.8rem;
    line-height: 1.7;
  `,
}

export const width = {
  spMargin: `2%`,
  content: 1000,
  contentWide: 1160,
  contentSlim: 840,
  contentText: 580,
}

export const style = {
  content: css`
    width: 92%;
    max-width: ${width.content}px;
    margin-left: auto;
    margin-right: auto;
  `,
  contentWide: css`
    margin-left: auto;
    margin-right: auto;
  `,
  contentSlim: css`
    width: 80%;
    max-width: ${width.contentSlim}px;
    margin-left: auto;
    margin-right: auto;
  `,
  contentText: css`
    width: 80%;
    max-width: ${width.contentText}px;
    margin-left: auto;
    margin-right: auto;
  `,
}

export const easing = {
  easeOutSine: "cubic-bezier(0.39, 0.575, 0.565, 1)",
  easeOutQuart: "cubic-bezier(0.165, 0.84, 0.44, 1)",
}

const imgRotateKf = keyframes`
from {
  opacity: 0;
  &::before {
    background: red;
  }
}
to {
  opacity: 1;
  &::before {
    background: blue;
  }
}
`
const imgRotateBgKf = keyframes`
from {
  opacity: 0;
}
to {
  opacity: 1;
  transform: rotate(-8deg);
}
`
const imgRotateBgKfPc = keyframes`
from {
  opacity: 0;
}
to {
  opacity: 1;
  transform: rotate(-3deg);
}
`
export const animation = {
  imgRotateBg: css`
    position: relative;
    opacity: 0;
    &::before {
      display: block;
      content: "";
      inset: 0;
      position: absolute;
      background: ${color.yellow};
    }
    &.show {
      animation: ${imgRotateKf} 1s 0.3s ${easing.easeOutSine} forwards;
      &::before {
        ${mq.sp(css`
          animation: ${imgRotateBgKf} 1.5s 0.3s ${easing.easeOutSine} forwards;
        `)}
        ${mq.pc(css`
          animation: ${imgRotateBgKfPc} 1.5s 0.3s ${easing.easeOutSine} forwards;
        `)}
      }
    }
  `,
}
