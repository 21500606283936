import { css } from "@emotion/react"
import { color, style } from "../utils/styles"

import { FaPhoneAlt, FaMapMarkerAlt } from "react-icons/fa"
import { IoIosMail } from "react-icons/io"

import FooterLogo from "../img/common/footer_logo.svg"
import FooterContact from "../img/common/footer_contact.svg"

const Footer = () => {
  return (
    <footer>
      <div
        css={css`
          background: ${color.main};
          padding: 16px 0 32px;
        `}
      >
        <div
          css={css`
            ${style.content}
            width: 100%;
          `}
        >
          <FooterLogo />
        </div>

        <div
          css={css`
            max-width: 400px;
            margin: 40px auto 0;
          `}
        >
          <div
            css={css`
              width: fit-content;
              margin: 0 auto;
              display: grid;
              grid-template-columns: 40px 1fr;
            `}
          >
            <div
              css={css`
                grid-area: 1/1/4/2;
                display: flex;
                align-items: center;
                border-right: solid 1px ${color.gray};
                margin-right: 5px;
              `}
            >
              <FaPhoneAlt
                size="20px"
                css={css`
                  color: ${color.yellow};
                `}
              />
            </div>
            <div>
              <a
                href="tel:04996-2-1745"
                css={css`
                  color: ${color.white};
                  font-size: 2rem;
                  font-weight: bold;
                  text-decoration: none;
                  width: min-content;
                  white-space: nowrap;
                `}
              >
                <span
                  css={css`
                    font-size: 1.2rem;
                  `}
                >
                  （平日）
                </span>
                04996-2-1745
              </a>
            </div>
            <div
              css={css`
                margin-top: 16px;
              `}
            >
              <a
                href="tel:080-8705-7717"
                css={css`
                  color: ${color.white};
                  font-size: 1.6rem;
                  font-weight: bold;
                  text-decoration: none;
                  width: min-content;
                  white-space: nowrap;
                `}
              >
                <span
                  css={css`
                    font-size: 1.2rem;
                  `}
                >
                  （土日祝日）
                </span>
                080-8705-7717
              </a>
            </div>
            <p
              css={css`
                margin-top: 16px;
                color: ${color.white};
                font-size: 1.2rem;
                font-weight: bold;
                padding-bottom: 10px;
              `}
            >
              【 受付時間　9:00〜20:00 】
            </p>
          </div>
          <a
            href="mailto:shukuhaku@across.co.jp"
            css={css`
              margin-top: 32px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: ${color.white};
              font-size: 1.6rem;
              text-decoration: none;
            `}
          >
            <IoIosMail
              size="26px"
              css={css`
                margin-right: 0.5em;
                color: ${color.yellow};
              `}
            />
            shukuhaku@across.co.jp
          </a>

          <p
            css={css`
              margin-top: 32px;
              color: ${color.white};
              font-size: 1.4rem;
              text-align: center;
              a {
                color: ${color.white};
                text-decoration: none;
              }
            `}
          >
            <a href="https://goo.gl/maps/GbMsq9qZiumB9Tqi6" target="_blank">
              <FaMapMarkerAlt
                size="21px"
                css={css`
                  margin-right: 0.5em;
                  color: ${color.yellow};
                `}
              />
              東京都八丈島八丈町三根1029-3
              <br />
              <span
                css={css`
                  font-size: 1.2rem;
                `}
              >
                《 八丈島空港、底土港から車で5分 》
              </span>
            </a>
          </p>

          <p
            css={css`
              margin-top: 32px;
              color: ${color.white};
              font-size: 1.4rem;
              text-align: center;
              a {
                color: ${color.white};
                text-decoration: none;
              }`}
          >
            <a href="http://across.co.jp/" target="_blank">
              運営：株式会社アクロス
            </a>
          </p>
        </div>
      </div>
      <div
        css={css`
          background: ${color.gray};
          font-size: 1rem;
          color: ${color.white};
          text-align: center;
          padding: 4px 0;
        `}
      >
        copyright 2021 &copy;アクロス
      </div>
    </footer>
  )
}

export default Footer
